<template>
    <div class="promise">
        <StackRouterHeaderBar :showTitle="true" :title="$translate('MAKE_SCHEDULE')" />
        <div class="title" v-html="$translate('send_reschedule_message'.toUpperCase())" />
        <div class="guide">
            <div class="text flex-row" v-for="i in [1, 2]" :key="i">
                <span v-html="$translate(`${type.toUpperCase()}_GUIDE_${i}`)" />
            </div>
        </div>
        <div class="content">
            <TextareaWithX v-model="dateMessage" :placeholder="placeholder" textareaRows="10" />
        </div>

        <BottomButton @click="submit" :disabled="disabled" :label="'메시지 보내기'" :blackBtn="true" />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'RescheduleMessagePage',
    props: ['message', 'isAtype', 'userInfo', 'type'],
    methods: {
        async submit() {
            // 메시지 보내는 api 연결 필요
            try {
                await scheduleService.createSchedule(this.content.schedule_id, {
                    reject_message: this.dateMessage,
                    b_message_id: this.message.id,
                })
                await this.$store.dispatch('loadChat', { chatId: this.message.chat_id, force: true })
                this.$stackRouter.popTo(2)
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
    data: () => ({
        dateMessage: null,
    }),
    computed: {
        placeholder() {
            return `ex. 제가 요즘 퇴근시간이 명확하지 않아 평일 오후는 힘들 것 같습니다. 제가 다음주부터는 칼퇴가 가능한데 다음주 평일은 어떠신가요? 24일 이후 평일에는 전부 가능합니다!`
        },
        disabled() {
            return !this.dateMessage
        },
        content() {
            return this.$mustParse(this.message.content)
        },
    },
}
</script>
<style scoped lang="scss">
.promise {
    height: calc(100vh - #{$stack-router-header-height});
    overflow-y: auto;

    .title {
        margin: 12px 0 12px 0;
        text-align: center;
        color: black;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        @include spoqa-f-bold;
    }
    .guide {
        background: #f1fefc;
        margin: 0 16px;
        padding: 8px 12px;
        line-height: 20px;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        .text::before {
            content: '*';
            margin: 0 2px;
        }
    }
    .content {
        margin: 0 16px;
    }
    .section {
        margin-bottom: 48px;
        padding: 0 16px;
        color: black;

        .desc-sm {
            font-size: 12px;
            color: black;
            line-height: 1.67;
            @include spoqa-f-regular;

            &.desc-flex {
                display: flex;
            }

            &.c-primary {
                @include spoqa-f-medium;
            }
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }
        .content {
            .grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 8px;

                &.isBtype {
                    grid-template-columns: repeat(2, 1fr) !important;
                }
            }
            .item {
                margin-top: 8px;
                padding: 9px 0;
                border: 1px solid $grey-04;
                border-radius: 4px;
                font-size: 14px;
                @include center;

                &.selected {
                    border: 1px solid $blue-primary;
                    background-color: #f1fffc;
                    //@include spoqa-f-medium;
                }

                &.date {
                    justify-content: unset;
                    font-size: 14px;
                    color: $grey-06;
                    padding-left: 16px;
                    border: 1px solid $grey-02;

                    .val {
                        color: black;
                    }

                    i {
                        font-size: 16px;
                        color: black;
                        margin-right: 8px;
                    }
                }

                &.selected {
                    border: 1px solid $blue-primary;
                    color: $blue-primary;
                }
            }
        }
    }
    ::v-deep .bottom-button {
        border: 1px solid white;
    }
}
</style>
